<template>
  <div>
    <ServerError v-if="ServerError" />

    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#68D389"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showSnackBar" color="#68D389" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12 md10 pt-4>
        <v-card outlined>
          <v-layout pa-3 wrap justify-center>
            <v-flex xs12 sm6 text-left>
              <span class="popsemibold" style="font-size: 18px">
                {{ storage.product.name }}
              </span>
              <v-btn plain text x-small color="red" :ripple="false">
                <span class="popregular text-none" style="font-size: 14px">
                  {{ storage.deliverystatus }}
                </span>
              </v-btn>
            </v-flex>
            <v-flex
              v-if="storage.deliverystatus != 'Cancelled'"
              xs12
              sm6
              md6
              lg6
              xl6
              text-right
              pr-3
            >
              <a
                target="_blank"
                :href="baseURL + '/purchase/getInvoicePdf?id=' + storage._id"
                class="downlink"
              >
                <span
                  style="
                    font-size: 16px;
                    font-family: poppinssemibold;
                    color: #68d389;
                  "
                >
                  Download Invoice</span
                >
              </a>
            </v-flex>
            <v-flex xs12>
              <v-layout wrap pa-2>
                <v-flex xs4 lg2 align-self-center>
                  <router-link :to="'/'">
                    <v-img
                      v-if="storage.product.images.length > 0"
                      width="175px"
                      height="113px"
                      :src="mediaURL + storage.product.images[0]"
                    >
                      <template v-slot:placeholder>
                        <ImageLoader />
                      </template>
                    </v-img>
                    <v-img
                      v-else
                      width="175px"
                      height="113px"
                      src="../../../assets/Images/no-image.png"
                    >
                      <template v-slot:placeholder>
                        <ImageLoader />
                      </template>
                    </v-img>
                  </router-link>
                </v-flex>
                <v-flex xs8 lg10 pl-4>
                  <v-layout wrap justify-start> </v-layout>
                  <v-layout wrap pt-2>
                    <v-flex md6 text-left>
                      <span class="popregular"> Date</span><br />
                      <span class="popregular" style="letter-spacing: 0px"
                        >{{ formatDate(storage.payment.init_date) }}
                      </span>
                      <!-- <v-layout wrap>
                        <v-flex md12 text-left>
                          <span class="textField"> Details</span>
                        </v-flex>
                        <v-flex md12 text-left>
                          <span class="popregular">{{
                            storage.payment.name
                          }}</span>
                          <br />
                          <span class="popregular">{{
                            storage.payment.email
                          }}</span>
                          <br />
                        </v-flex>
                      </v-layout> -->
                    </v-flex>

                    <v-flex md6>
                      <v-layout wrap>
                        <v-flex md12 text-left>
                          <span class="popregular"> Address</span>
                        </v-flex>
                        <v-flex
                          md12
                          text-left
                          v-if="storage.payment.deliveryaddress"
                        >
                          <span class="popregular"
                            >{{ storage.payment.deliveryaddress.firstname }}
                            {{ storage.payment.deliveryaddress.lastname }}</span
                          >
                          <br />
                          <span class="popregular">{{
                            storage.payment.deliveryaddress.email
                          }}</span>
                          <br />
                          <span class="popregular">{{
                            storage.payment.deliveryaddress.phone
                          }}</span>
                          <br />
                          <span class="popregular">{{
                            storage.payment.deliveryaddress.streetAddress
                          }}</span>
                          <br />
                          <span class="popregular"
                            >{{ storage.payment.deliveryaddress.city }}
                            {{ storage.payment.deliveryaddress.district }}
                            {{ storage.payment.deliveryaddress.state }}</span
                          >
                          <br />
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-divider></v-divider>
              <v-layout wrap v-if="storage.trackingid" pa-3>
                <v-flex md4 text-left>
                  <span class="popregular">Tracking Id</span>
                </v-flex>
                <v-flex md8 text-right>
                  <span class="popregular">{{ storage.trackingid }}</span>
                </v-flex>
                <v-flex xs12>
                  <v-layout wrap v-if="storage.courierservice">
                    <v-flex md4 text-left>
                      <span class="popregular">Courier Service</span>
                    </v-flex>
                    <v-flex md8 text-right>
                      <span class="popregular">{{
                        storage.courierservice
                      }}</span>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-divider></v-divider>
              <v-layout wrap pa-4>
                <v-flex md4 text-left v-if="storage.unitname">
                  <span class="popregular1">Type</span>
                </v-flex>
                <v-flex md8 text-right v-if="storage.unitname">
                  <span class="popregular">{{ storage.unitname }}</span>
                </v-flex>
                <v-flex md4 text-left>
                  <span class="popregular1">Quantity</span>
                </v-flex>
                <v-flex md8 text-right>
                  <span class="popregular"
                    >{{ storage.sizename }} x {{ storage.quantity }}</span
                  >
                </v-flex>
              </v-layout>
              <v-divider></v-divider>
              <v-layout wrap pa-4>
                <v-flex md12 text-left>
                  <span class="textField">Payment Details</span>
                </v-flex>
                <v-flex md12 text-left>
                  <v-layout wrap>
                    <v-flex md4 text-left>
                      <span class="popregular">Amount</span>
                    </v-flex>
                    <v-flex md8 text-right>
                      <span class="popregular"
                        >{{ storage.productPrice }} INR</span
                      >
                    </v-flex>
                  </v-layout>
                  <v-layout wrap>
                    <v-flex md4 text-left>
                      <span class="popregular">Delivery Charge</span>
                    </v-flex>
                    <v-flex md8 text-right>
                      <span class="popregular"
                        >{{ Math.round(storage.deliveryCharge) }} INR</span
                      >
                    </v-flex>
                  </v-layout>

                  <v-layout wrap>
                    <v-flex md4 text-left>
                      <span class="popregular">Tax</span>
                    </v-flex>
                    <v-flex md8 text-right>
                      <span class="popregular">{{ storage.tax }} INR</span>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap>
                    <v-flex md4 text-left>
                      <span class="popregular">Payment Gateway Charge</span>
                    </v-flex>
                    <v-flex md8 text-right>
                      <span class="popregular"
                        >{{
                          Math.round(storage.paymentgatewayCharge)
                        }}
                        INR</span
                      >
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-divider></v-divider>
              <v-layout wrap pa-4>
                <v-flex md4 text-left>
                  <span class="popsemibold">GRAND TOTAL</span>
                </v-flex>
                <v-flex md8 text-right>
                  <span class="popsemibold"
                    >{{ Math.round(storage.total) }} INR</span
                  >
                </v-flex>
              </v-layout>

              <v-layout wrap pa-6 v-if="storage.deliverystatus == 'Delivered'">
                <v-flex xs12 align-self-center>
                  <v-card outlined class="rounded-xl">
                    <v-layout wrap pa-2>
                      <v-flex md4 text-left pa-2>
                        <span style="color: blue" class="popsemibold"
                          >Rate & Review Product</span
                        >
                      </v-flex>
                      <v-flex xs12 text-right>
                        <v-layout wrap justify-center>
                          <v-flex xs12 sm3 pa-2 align-self-center text-left>
                            <!-- <span style="color:blue">Rate us</span> -->
                            <!-- <v-img :src="require('./../../../assets/Images/rate.png')"></v-img> -->
                            <v-rating
                              v-model="rating"
                              color="orange"
                              background-color="grey darken-1"
                              empty-icon="$ratingFull"
                              hover
                              large
                            ></v-rating>
                          </v-flex>
                          <!-- <v-flex
                            sm1
                            align-self-center
                            text-center
                            class="hidden-xs-only"
                          >
                            <v-divider vertical></v-divider>
                          </v-flex> -->
                          <v-flex xs12 sm9 align-self-center pa-2>
                            <v-textarea
                              label="Comment"
                              :color="appColor"
                              outlined
                              v-model="comment"
                              class="texty"
                            ></v-textarea>
                          </v-flex>
                          <v-flex xs12 pa-2 align-self-center text-right>
                            <v-btn
                              small
                              tile
                              dark
                              :color="appColor"
                              @click="validateRev()"
                            >
                              Submit
                            </v-btn>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";

export default {
  props: ["storage"],

  data() {
    return {
      appLoading: false,
      showSnackBar: false,
      timeout: 5000,
      product: [],
      msg: null,
      purchaseData: [],
      ServerError: false,
      rating: 0,
      comment: null,
    };
  },
  beforeMount() {
    if (this.storage.rating) {
      this.rating = this.storage.rating.rating;
      this.comment = this.storage.rating.comment;
    }
  },

  methods: {
    validateRev() {
      if (!this.comment) {
        this.msg = "Please provide a comment";
        this.showSnackBar = true;
      } else this.addReview();
    },
    addReview() {
      axios({
        method: "POST",
        url: "/Review/Add/",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          productid: this.storage.product._id,
          rating: this.rating,
          comment: this.comment,
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.$emit("stepper", {
              pageResponse: response.data.msg,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 10) + " " + year;
      return strTime;
    },
    formatTime(item) {
      if (!item) return;
      var hours = Number(item.split(":")[0]);
      var minutes = Number(item.split(":")[1]);
      var ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    },
  },
};
</script>
<style>
.texty {
  font-family: poppinsregular !important;
}
</style>
